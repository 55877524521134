.homecontent {
    padding: 75px 36px 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}
.homecontent-home {
    background-color: #0b0b0e;
    color: #f9f9f9;
    background-image: url(https://static.paraluni.org/images/infiweb/home_bg1.png);
    background-position: center center;
    background-size: cover;
}
.homecontent-home-title {
    font-family: Arial_Black;
    font-size: 26px;
    line-height: 1.67;
    letter-spacing: 0.48px;
    text-align: left;
    color: #f9f9f9;
    margin-bottom: 48px;
}
.homecontent-home-subtitle {
    font-size: 20px;
    line-height: 1.33;
    letter-spacing: 0.36px;
    text-align: left;
    margin-bottom: 48px;
}
.homecontent-home-start {
    display: inline-block;
    padding: 7px;
    border: solid 1px #fff;
    margin-bottom: 48px;
}
.homecontent-home-startCont {
    padding: 10px 30px;
    text-align: center;
    background-color: #fff;
    color: #0f1923;
    font-weight: bold;
}

.homecontent-about {
    color: #424242;
}

.homecontent-about-title {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 12px;
}
.homecontent-about-subtitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.32px;
    margin-bottom: 60px;
}
.homecontent-about-icon {
    width: 12px;
    height: 12px;
    margin-left: 50px;
    margin-right: 5px;
    margin-top: 3px;
}
.homecontent-about-subhomecontent {
    font-size: 14px;
    line-height: 1.43;
    margin-bottom: 48px;
}

.homecontent-stand {
    background: #ece8e1;
    padding: 75px 0 48px;
}
.homecontent-stand-title {
    font-size: 30px;
    font-weight: 800;
    line-height: 1.33;
    margin-bottom: 24px;
    margin-left: 36px;
}

.homecontent-stand-homecontent {
    overflow-x: auto;
}

.standitem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0 0 40px;
    background-color: #fff;
    margin-right: 36px;
}
.standitem-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.8;
    margin-bottom: 12px;
}
.standitem-sub {
    width: 238px;
    min-height: 157px;
    padding-bottom: 12px;
    font-size: 16px;
    padding-right: 40px;
    line-height: 1.43;
    text-align: left;
}
.standitem-img {
    width: 70px;
    height: 70px;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 14px;
    margin-right: 14px;
    

}

.homecontent-solutions .ant-carousel-vertical .slick-dots{
    top: 31%;
}
.homecontent-solutions .ant-carousel .slick-dots li.slick-active {
    width: 4px;
    height: 48px;
    background-color: #ff3150;
}

.homecontent-solutions .ant-carousel .slick-dots li {
    width: 4px;
    height: 48px;
    background-color: rgba(15, 25, 35, 0.08);
}

.homecontent-solutions .ant-carousel .slick-dots li.slick-active button {
    width: 4px;
    height: 48px;
    background-color: #ff3150;
}

.homecontent-solutions .ant-carousel .slick-dots li button {
    width: 4px;
    height: 48px;
    background-color: rgba(15, 25, 35, 0.08);
}


.homecontent-focus {
    background-color: #0b0b0e;
    color: #fff;
}
.homecontent-focus-title {
    font-size: 30px;
    font-weight: 800;
    line-height: 1.33;
    margin-bottom: 24px;
}

.homecontent-focus-view {
    padding: 40px 10px 20px;
    border: solid 1px rgba(255, 49, 80, 0.2);
    background-color: rgba(255, 49, 80, 0.1);
}

.focus-main {
    font-size: 40px;
    font-weight: bold;
    line-height: 0.6;
    color: rgb(255, 49, 80);
    margin-bottom: 24px;
}
.focus-sub {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
}
.mb-10 {
    margin-bottom: 10px;
}
.mr-10 {
    margin-right: 10px;
}

.homecontent-solutions-title {
    font-size: 30px;
    font-weight: 800;
    line-height: 1.33;
    margin-bottom: 12px;
}

.solutionsItem-title {
    font-size: 14px;
    font-weight: bold;
    color: #f44336;
    margin-bottom: 12px;
}
.solutionsItem-line {
    width: 48px;
    height: 3px;
    margin-bottom: 16px;
    background-color: #f44336;
}
.solutionsItem-sub {
    margin-right: 60px;
    width: 240px;
    height: 185px;
    font-size: 14px;
    line-height: 1.43;
}
.solutionsItem-ver {
    margin-left: auto;
    align-self: start;
}
.ver {
    width: 4px;
    height: 30px;
    margin: 0 0 10px;
    background-color: rgba(15, 25, 35, 0.08);
}
.red {
    background-color: #f44336;
}
.solutionsItem-img {
    margin-top: 12px;
    width: 100%;
    height: 162px;
    background-color: #0f1923;
    margin-bottom: 5px;
}

.homecontent-overall {
    background-color: #ece8e1;
    padding: 75px 0 48px;
}
.homecontent-overall-title {
    font-size: 30px;
    font-weight: 800;
    line-height: 1.33;
    margin-bottom: 12px;
    text-align: center;
}
.homecontent-overall-main {
    overflow-x: auto;
    margin-left: 36px;
}
.overallList {
    margin-bottom: 10px;
    width: 600px;
}
.overallList-title {
    padding: 10px 8px;
    background-color: #000;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
    color: #fff;
    margin-right: 10px;
}
.overallList-subList1 {
    display: flex;
}
.overallsub-item {
    max-width: 180px;
    padding: 2px 4px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #424242;
    margin-right: 10px;
}
.w120 {
    width: 120px;
    height: 20px;
    line-height: 20px;
    padding: 2px 4px;
    text-align: center;
}
.mt-10 {
    margin-top: 10px;
}

.homecontent-roadmap-title {
    font-size: 30px;
    font-weight: 800;
    line-height: 1.33;
    text-align: left;
    color: #424242;
    margin-bottom: 12px;
}

.roadmap-main {
    margin-bottom: 70px;
}
.yearView {
    align-items: flex-end;
}
.yearClass {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding-bottom: 10px;
    border-bottom: 2px solid #000;
    margin-right: 40px;
}
.redyear {
    color: #f44336;
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    padding-bottom: 8px;
    border-bottom: 2px solid #f44336;
}

.roadmap-main {
    overflow-x:auto;
    flex-wrap: nowrap;
    position: relative;
}
.yearData-view {
    width: 260px;
    height: 240px;
    flex-shrink:0;
    margin-right: 42px;
    position: relative;
    background-color: #ece8e1;
    background-image: url(https://static.paraluni.org/images/infiweb/roadmap_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 12px 16px;
}
.year-text{
    flex-wrap: nowrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42;
    color: #1b1a1a;
    margin-bottom: 24px;
    align-items: center;
}
.year-icon{
    width: 10px;
    height: 8px;
    margin-right: 5px;
}
.year_ji{
    position: absolute;
    right: 24px;
    bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    color: #000;
}