.rgb-fontA{
    font-family: Arial_Black;
    font-size: 40px;
    color: #f9f9f9;
    letter-spacing: 10px;
}
.rgb-des{
    padding: 20px 65px 20px 20px;
    border-radius: 2px;
    background-color:#424242;
    color: #f9f9f9;
    font-size: 18px;
    letter-spacing: 1px;
    word-spacing: 2px;
}
.rgb-design-img{
    position: absolute;
    transition: all ease-in 0.5s;
}

.rgbd-img-unselect{
    width: 281px;
    height: 370px;
    opacity: 0;
}
.rgbd-img-select{
    width: 586px;
    height: 501px;
    z-index: 1;
}
.rgb-download-out{
    width: 280px;
    height: 72px;
    border: solid 1px rgb(15, 25, 35);
    padding: 7px;
    margin-top: 40px;
    cursor: pointer;
    margin-bottom: 200px;
}
.rgb-download-in{
    background-color: rgb(15, 25, 35);
    width: 100%;
    height: 100%;
}
.road-time-common-aa{
    font-size: 16px;
    font-weight: 500;
    color: #424242;
}
.road-time-check-aa{
    font-size: 24px;
    font-weight: bold;
    color: #f44336;
}
.road-line-common-aa{
    width: 40px;
    height: 2px;
    margin-top: 5px;
    background-color: #424242;
}
.road-line-check-aa{
    width: 40px;
    height: 2px;
    margin-top: 5px;
    background-color: #f44336;
}
.learn-more{
    width: 204px;
    height: 58px;
    border: solid 1px rgb(15, 25, 35);
    display: flex;justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #424242;
    margin-top: 50px;
}
