.hps-top{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.hps-fontA{
    font-size: 16px;
    font-weight: bold;
    color: #ff3150;
}

.hps-line{
    width: 54px;
    height: 2px;
    margin-top: 11px;
    background-color: rgb(255, 49, 80);
    transition: width 0.5s;
}
.hps-top:hover > .hps-line{
    width: 300px
}
