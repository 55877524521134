.rgb-title {
    font-family: Arial_Black;
    font-size: 24px;
    line-height: 1.67;
    color: #f9f9f9;
    margin-bottom: 24px;
    padding: 36px 36px 0;
}
.flex-shrink {
    flex-shrink: 0;
}
.rgb-header {
    background-color: #0b0b0e;
}
.rgb-content {
    overflow-x: auto;
    padding: 0 36px;
    font-size: 14px;
    color: #fff;
    padding-bottom: 170px;

}
.rgb-contentItem {
    width: 302px;
    height: 240px;
    padding: 20px;
    background-color: #363636;
    margin-right: 20px;
    position: relative;
}
.rgb-contentItemImg{
    position: absolute;
    width: 70px;height: 70px;
}

.rgb-desing {
    padding: 48px 36px;
}

.desing-title {
    font-size: 30px;
    font-weight: 800;
    line-height: 1.33;
    margin-bottom: 12px;
    text-align: center;
}

.desingItem-title {
    font-size: 14px;
    font-weight: bold;
    color: #f44336;
    margin-bottom: 12px;
}
.desingItem-line {
    width: 48px;
    height: 3px;
    margin-bottom: 16px;
    background-color: #f44336;
}
.desingItem-sub {
    margin-right: 60px;
    width: 240px;
    font-size: 14px;
    line-height: 1.43;
    height: 450px;
}
.desingItem-img {
    margin-top: 24px;
    width: 100%;
    height: 162px;
    background-color: #0f1923;
    object-fit: cover;
}


.rgb-main {
    background-color: #ece8e1;
}
.rgb-main-title {
    font-size: 30px;
    font-weight: 800;
    color: #424242;
    margin-bottom: 24px;
}
.rgb-main-c {
    overflow-x: auto;
}
.rgb-main-cI {
    width: 150px;
    margin-right: 36px;
}
.rgb-main-cI-title {
    font-size: 20px;
    font-weight: 600;
    color: #424242;
    margin-bottom: 12px;
}
.rgb-main-cI-sub {
    font-size: 14px;
    color: #424242;
    margin-bottom: 20px;
}
.rgb-main-cI-img {
    width: 70px;
}
.infinitas-title,
.rgb-code-title {
    font-size: 30px;
    font-weight: 800;
    line-height: 1.33;
    color: #424242;
    margin-bottom: 24px;
}
.rgb-code-content {
    font-size: 14px;
    text-align: left;
    color: #424242;
    margin-bottom: 48px;
}
.rgb-code-view {
    padding: 48px 0;
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0);
    background-color: #8b8b8b;
    margin-bottom: 36px;
    background: url(https://static.paraluni.org/images/infiweb/rgb_bg8.png);
    background-position: center center;
    background-size: cover;

}
.rgb-code-btn {
    width: 200px;
    padding: 18px 0 17px;
    text-align: center;
    margin: 0 auto;
    border: solid 1px #fff;
    display: block;
    text-decoration: none;
    color: #fff;
}
.rgb-code-down {
    margin: 0 10px;
    padding: 7px;
    border: solid 1px #0f1923;
}
.rgb-code-viewbtn {
    padding: 6px 0;
    text-align: center;
    background-color: #0f1923;
    display: block;
    color: #fff;
    text-decoration: none;
}
.rgb-code-viewSub {
    font-size: 12px;
}
.rgb-code-viewdown {
    font-size: 16px;
    font-weight: bold;
}

.infinitas-item {
    margin-bottom: 48px;
}
.endImg {
    flex-direction: row-reverse !important;
}
.infinitas-itemImg {
    width: 78px;
    height: 78px;
}
.infinitas-itemText {
    font-size: 14px;
    color: #424242;
}
.rgb-video {
    background-color: #0b0b0e;
    padding: 48px 0;
}
.rgb-video-title {
    font-size: 30px;
    text-align: center;
    color: #fff;
    font-weight: 800;
}
.rgb-video-link {
    display: block;
    font-size: 14px;
    line-height: 1.43;
    color: #fff;
    text-align: center;
}





.rgb-desingView .ant-carousel-vertical .slick-dots{
    top: 31%;
}
.rgb-desingView .ant-carousel .slick-dots li.slick-active {
    width: 4px;
    height: 48px;
    background-color: #ff3150;
}

.rgb-desingView .ant-carousel .slick-dots li {
    width: 4px;
    height: 48px;
    background-color: rgba(15, 25, 35, 0.08);
}

.rgb-desingView .ant-carousel .slick-dots li.slick-active button {
    width: 4px;
    height: 48px;
    background-color: #ff3150;
}

.rgb-desingView .ant-carousel .slick-dots li button {
    width: 4px;
    height: 48px;
    background-color: rgba(15, 25, 35, 0.08);
}
