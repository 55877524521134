.home-pc {

}

.hpo-fontA {
    color: #f9f9f9;
    font-size: 40px;
    width: 950px;
    font-family: Arial_Black;
    line-height: 1.75;
    word-spacing: 10px;
    letter-spacing: 5px;
    text-shadow:rgba(0,0,0,0.5) 3px 4px 5px;
}

.hpo-fontB {
    font-size: 18px;
    width: 612px;
    margin-top: 50px;
    color: #f9f9f9;
    word-spacing: 1px;
    letter-spacing: 2px;
}

.hpo-start-border {
    margin-top: 100px;
    padding: 7px;
    border: solid 1px rgb(255, 255, 255);
    width: fit-content;
}

.hpo-start {
    width: 100px;
    height: 40px;
    background-color: #ffffff;
    font-family: MuktaMahee;
    font-size: 14px;
    font-weight: bold;
    color: #0f1923;
    cursor: pointer;
}

.height-900 {
    height: 900px;
}
.height-scale{
    height: calc(100vh - 84px);
}
.hp-fontC {
    font-size: 40px;
    font-weight: 800;
    color: #424242;
}

.hp-fontD {
    font-size: 24px;
    font-weight: 600;
    color: #424242;
    margin-top: 48px;
    width: 587px;
    letter-spacing: 0.48px;
    word-spacing: 1px;
}

.stand-fontA {
    font-size: 40px;
    font-weight: 800;
    color: #424242;
}

.solutions{

}


.solutions .ant-carousel .slick-dots li.slick-active {
    width: 6px;
    height: 50px;
    background-color: #ff3150;
}

.solutions .ant-carousel .slick-dots li {
    width: 6px;
    height: 50px;
    background-color: rgba(15, 25, 35, 0.08);
}

.solutions .ant-carousel .slick-dots li.slick-active button {
    width: 6px;
    height: 50px;
    background-color: #ff3150;
}

.solutions .ant-carousel .slick-dots li button {
    width: 6px;
    height: 50px;
    background-color: rgba(15, 25, 35, 0.08);
}

.road-time{
    cursor: pointer;
}
.road-time-common{
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
}
.road-time-check{
    font-size: 24px;
    font-weight: bold;
    color: #f44336;
}
.road-line-common{
    width: 40px;
    height: 2px;
   margin-top: 5px;
    background-color: #ffffff;
}
.road-line-check{
    width: 40px;
    height: 2px;
    margin-top: 5px;
    background-color: #f44336;
}
