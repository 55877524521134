.network-fontA{
    font-size: 40px;
    color: #424242;
    font-family: Arial_Black;
    letter-spacing: 5px;
}
.network-fontB{
    color: #424242;
    font-size: 18px;
    width: 612px;
}
.network-red-line{
    width: 179px;
    height: 5px;
    background-color: #ff3150;
}
.network-circle{
    width: 204px;
    height: 58px;
    font-size: 14px;
    font-weight: bold;
    border: solid 1px rgb(15, 25, 35);
    margin-top: 30px;
    cursor: pointer;
}
