.snark-img-main{
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    background-color: rgba(255, 255, 255,0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.snark-img-main-img{
    width: 166px;
    height: 166px;
}
.snark-img-main-text{
    margin-top: 54px;
}
.snark-follow{

}
.snark-follow .ant-carousel .slick-dots li.slick-active {
    width: 6px;
    height: 50px;
    background-color: #ff3150;
}

.snark-follow .ant-carousel .slick-dots li {
    width: 6px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.08);
}

.snark-follow .ant-carousel .slick-dots li.slick-active button {
    width: 6px;
    height: 50px;
    background-color: #ff3150;
}

.snark-follow .ant-carousel .slick-dots li button {
    width: 6px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.08);
}
