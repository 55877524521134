.contactView {
    background-color: #ece8e1;
    padding: 50px 20px;

}
.contactT {
    font-family:Arial_Black;
    font-size: 24px;
    text-align: left;
    color: #424242;
}
.contactItem {
    margin-bottom: 40px;
}
.contactImg {
    width: 60px;
    height: 60px;
}
.contactContent {
    margin-left: 24px;
}
.contactTitle {
    margin-top: 6px;
    font-size: 18px;
    font-weight: 800;
    color: #ff3150;
    margin-bottom: 12px;
}
.contactSub {
    font-size: 12px;
    line-height: 1.4;
    color: #0f1923;
}
