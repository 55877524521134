.hpr-main{
    background-color: #000000;
    height: 561px;
    margin-right: 60px;
    flex-shrink: 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 33px;
    padding-left: 40px;
    padding-right: 60px;
}
.hpr-fontA{
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    margin-left: 15px;
}
.hpr-fontB{
    font-size: 40px;
    font-weight: 500;
    color: #ffffff;
    margin-left: 5px;
    line-height: 40px;
}
