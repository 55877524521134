.pc-header-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #0f1923;
    z-index: 999;
}
.pc-header {
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 84px;
}

.pch-tag {
    padding: 4px 12px;
    font-size: 16px;
    font-weight: 800;
    color: #ffffff;
    cursor: pointer;
}

.pch-tag:hover {
    color: #424242;
    background: linear-gradient(-135deg, transparent 5px, #ece8e1 0);
}

.pch-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 50px;
    border-radius: 0 0 12px 0;
    padding-left: 20px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}

.pchc-text {
    color: rgb(255, 255, 255);
    font-size: 18px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: border-bottom-width 0.5s ease-in;
}
.pchc-text:hover{
    border-bottom: 1px solid #ff3150;
    color: #ff3150;
}
