.content {
    padding: 48px 36px;
}
.zk-text {
    font-size: 30px;
    font-weight: 800;
}
.colorB {
    color: #424242;
}
.colorF {
    color: #fff;
}
.zk-header-img {
    width: 88px;
    height: 88px;
    margin-left: 60px;
}
.zk-header-line {
    width: 140px;
    height: 2px;
    background-color: #ff3150;
    margin: 12px 0;
}
.zk-sub-title {
    width: 280px;
    font-size: 14px;
}

.zk-main {
    background: #0b0b0e;
}
.zkmainItem {
}
.zkmainItem-img {
    margin-top: 24px;
    width: 220px;
    height: 174px;
    background-color: #fff;
    margin-bottom: 12px;
}
.zkmainItem-sub {
    margin-right: 60px;
    width: 220px;
    height: 260px;
    font-size: 14px;
    line-height: 1.43;
    color: #fff;
}
.zkmainItem-ver {
    margin-left: auto;
}
.zkmainItem-ver .ver {
    height: 50px;
    background-color: rgba(255, 255, 255, 0.08);
}
.zkmainItem-ver .red {
    background-color: #f44336;
}
.zk-main-top {
    margin: 48px 0 24px;
}
.zk-main-sub {
    font-size: 12px;
}
.zk-bg {
    flex-shrink: 0;
    background-color: rgba(255, 255, 255,.1);
    color: #fff;
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-right: 10px;
    box-sizing: border-box;
    flex-direction: column;
    width: 200px;
}
.zk-bg-main {
    overflow-x: auto;
}
.zk-bg-img {
    width: 98px;
    height: 98px;
    margin-bottom: 30px;
}
.zk-bg1 {
    margin-right: 6px;
    margin-bottom: 6px;
}
.zk-bg2 {
    margin-bottom: 6px;
}
.zk-bg3 {
    margin-right: 6px;
}
.zk-bg4 {
    margin-right: 6px;
}
.zk-bg5 {
}

.zk-dec {
    background-color: #ece8e1;
}
.zk-dec-text {
    font-size: 14px;
    margin-bottom: 48px;
}
.zk-mb-24 {
    margin-bottom: 24px;
}
.zk-em {
    background-color: #0b0b0e;
    height: 500px;
}
.zk-reference-item {
    padding: 0 40px;
    text-align: center;
    margin-bottom: 72px;
}
.zk-reference-item-img {
    width: 46px;
    height: 46px;
    margin-bottom: 12px;
}
.zk-reference-item-title {
    font-size: 14px;
    font-weight: bold;
    color: #0f1923;
    margin-bottom: 6px;
}
.zk-reference-item-link {
    font-size: 12px;
    color: #424242;
    margin-bottom: 12px;
}
.zk-reference-item-btn {
    width: 200px;
    padding: 20px 0;
    border: solid 1px #0f1923;
}
.mb50 {
    margin-bottom: 50px;
}


.zk-main .ant-carousel-vertical .slick-dots{
    top: 31%;
    inset: inline end 0;
}
.zk-main .ant-carousel .slick-dots li.slick-active {
    width: 4px;
    height: 48px;
    background-color: #ff3150;
}

.zk-main .ant-carousel .slick-dots li {
    width: 4px;
    height: 48px;
    background-color: rgba(255, 255, 255, 0.08);
}

.zk-main .ant-carousel .slick-dots li.slick-active button {
    width: 4px;
    height: 48px;
    background-color: #ff3150;
}

.zk-main .ant-carousel .slick-dots li button {
    width: 4px;
    height: 48px;
    background-color: rgba(255, 255, 255, 0.08);
}

