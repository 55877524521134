.hpf{
    background-color: rgba(255, 49, 80, 0.1);
    height: 307px;
    border-radius: 15px 0 20px 0;
    border: solid 0.5px rgb(255, 49, 80);
}
.hpf-fontA{
    font-size: 66px;
    font-weight: bold;
    color: #ff3150;
}
.hpf-fontB{
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 1.1;
}
